import { Update } from "@ngrx/entity";
import { createActionGroup, emptyProps, props } from "@ngrx/store";

import {
  ChatbotMessage,
  SendMessageResponse,
} from "../models/chatbot-message.model";

export const ChatbotMessageActions = createActionGroup({
  source: "ChatbotMessage/API",
  events: {
    "Send Message Request": props<{ message: string }>(),
    "Send Message Request Successful": props<SendMessageResponse>(),
    "Send Message Request Failed": props<{ error: any }>(),

    //DEFAULT
    "Load ChatbotMessages": props<{ chatbotMessages: ChatbotMessage[] }>(),
    "Add ChatbotMessage": props<{ chatbotMessage: ChatbotMessage }>(),
    "Upsert ChatbotMessage": props<{ chatbotMessage: ChatbotMessage }>(),
    "Add ChatbotMessages": props<{ chatbotMessages: ChatbotMessage[] }>(),
    "Upsert ChatbotMessages": props<{ chatbotMessages: ChatbotMessage[] }>(),
    "Update ChatbotMessage": props<{
      chatbotMessage: Update<ChatbotMessage>;
    }>(),
    "Update ChatbotMessages": props<{
      chatbotMessages: Update<ChatbotMessage>[];
    }>(),
    "Delete ChatbotMessage": props<{ id: string }>(),
    "Delete ChatbotMessages": props<{ ids: string[] }>(),
    "Clear ChatbotMessages": emptyProps(),
  },
});
