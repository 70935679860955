import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import {
  catchError,
  concatMap,
  EMPTY,
  exhaustMap,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { v4 as uuidv4 } from "uuid";
import {
  ChatbotActions,
  ChatbotMessageActions,
  ChatbotSessionActions,
} from "../actions";
import { ChatbotMessageType, SendMessageResponse } from "../models";
import { chatbotSessionsFeature } from "../reducers";
import { chatbotFeature, TopicStatus } from "../reducers/chatbot.reducer";
import { ChatbotService, ChatbotSessionService } from "../services";
import { AuthActions } from "@api/authentication";

@Injectable()
export class ChatbotEffectEffects {
  protected actions$ = inject(Actions);
  protected store = inject(Store);
  protected sessionService = inject(ChatbotSessionService);
  protected chatbotService = inject(ChatbotService);

  onChatOpened$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatbotActions.chatbotOpened),
        switchMap(() =>
          this.store.select(chatbotSessionsFeature.selectCurrentSessionId)
        ),
        mergeMap((currentSessionId) => {
          if (currentSessionId) {
            return [];
          } else {
            return [ChatbotActions.chatbotInit()];
          }
        })
      ),
    { dispatch: true }
  );

  onChatbotInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbotActions.chatbotInit),
      withLatestFrom(this.store.select(chatbotFeature.selectTopicsStatus)),
      exhaustMap(([, status]) => {
        if (status === TopicStatus.LOADED) {
          return EMPTY;
        }
        return this.sessionService.getTopics().pipe(
          map((response) =>
            ChatbotActions.requestTopicsSuccess({ topics: response })
          ),
          catchError((error) =>
            of(ChatbotActions.requestTopicsFailed({ error }))
          )
        );
      })
    )
  );

  onSendMessageRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbotMessageActions.sendMessageRequest),
      withLatestFrom(
        this.store.select(chatbotSessionsFeature.selectCurrentSessionId)
      ),
      concatMap(([{ message }, sessionId]) =>
        this.chatbotService
          .sendMessage({
            sessionId,
            query: message,
          })
          .pipe(
            map((response: SendMessageResponse) =>
              ChatbotMessageActions.sendMessageRequestSuccessful(response)
            ),
            catchError((error) =>
              of(ChatbotMessageActions.sendMessageRequestFailed({ error }))
            )
          )
      )
    )
  );
  onMessageRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbotMessageActions.sendMessageRequest),
      withLatestFrom(
        this.store.select(chatbotSessionsFeature.selectCurrentSessionId)
      ),
      map(([{ message }, sessionId]) =>
        ChatbotMessageActions.addChatbotMessage({
          chatbotMessage: {
            id: uuidv4(),
            sessionId: sessionId,
            type: ChatbotMessageType.MESSAGE,
            sender: "user",
            text: message,
            createdAt: new Date().toISOString(),
          },
        })
      )
    )
  );

  onMessageRequestSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbotMessageActions.sendMessageRequestSuccessful),
      map((response: SendMessageResponse) =>
        ChatbotMessageActions.addChatbotMessage({
          chatbotMessage: {
            id: uuidv4(),
            sessionId: response.sessionId,
            type: ChatbotMessageType.MESSAGE,
            sender: "bot",
            text: response.response,
            createdAt: new Date().toISOString(),
          },
        })
      )
    )
  );

  onLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.reset,
        AuthActions.logout,
        AuthActions.unauthenticated
      ),
      mergeMap(() => [
        ChatbotSessionActions.clearChatbotSessions(),
        ChatbotMessageActions.clearChatbotMessages(),
      ])
    )
  );
}
