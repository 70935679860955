export enum UserStatus {
  active = 3,
  unverified = 53,
  invited = 5,
  "pending-request" = 6,
  deleted = 7,
  suspended = 8,
  draft = 9,
  incomplete = 10,
  declined = 17,
  refused = 50,
  pending = 2,
  "no-categories" = 52,
}
