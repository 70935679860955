import { inject, Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http";
import { SendMessageRequest, SendMessageResponse } from "../models";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ChatbotService {
  protected backendService = inject(BackendHttpClient);

  public sendMessage(
    data: SendMessageRequest
  ): Observable<SendMessageResponse> {
    return this.backendService
      .post("chatbot/query", data)
      .pipe(map((response) => response as SendMessageResponse));
  }
}
