import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { ChatbotSession } from "../models";
import { ChatbotTopic } from "../models/chatbot-topic.model";
import { ChatbotActions } from "../actions";

export const chatbotFeatureKey = "chatbot";

export enum TopicStatus {
  INITIAL = "initial",
  LOADING = "loading",
  LOADED = "loaded",
  FAILED = "failed",
}

export interface State {
  topics: ChatbotTopic[];
  topicsStatus: TopicStatus;
  topicsError: any;
}

export const adapter: EntityAdapter<ChatbotSession> =
  createEntityAdapter<ChatbotSession>();

export const initialState: State = {
  topics: [],
  topicsError: null,
  topicsStatus: TopicStatus.INITIAL,
};

export const reducer = createReducer(
  initialState,
  on(ChatbotActions.requestTopics, (state, action) => ({
    ...state,
    topicsStatus: TopicStatus.LOADING,
  })),
  on(ChatbotActions.requestTopicsSuccess, (state, action) => ({
    ...state,
    topics: action.topics,
    topicsStatus: TopicStatus.LOADED,
  })),
  on(ChatbotActions.requestTopicsFailed, (state, action) => ({
    ...state,
    topicsError: action.error,
    topicsStatus: TopicStatus.FAILED,
  }))
);

export const chatbotFeature = createFeature({
  name: chatbotFeatureKey,
  reducer,
  extraSelectors: () => ({}),
});
