import { createFeature, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ChatbotSession, ChatbotSessionStatus } from "../models";
import { ChatbotSessionActions } from "../actions";

export const chatbotSessionsFeatureKey = "chatbotSessions";

export interface State extends EntityState<ChatbotSession> {
  // additional entities state properties
  currentSessionId: string | null;
  status: ChatbotSessionStatus;
}

export const adapter: EntityAdapter<ChatbotSession> =
  createEntityAdapter<ChatbotSession>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  currentSessionId: null,
  status: ChatbotSessionStatus.NotInitialized,
});

export const reducer = createReducer(
  initialState,
  on(ChatbotSessionActions.setCurrentSessionId, (state, action) => ({
    ...state,
    currentSessionId: action.sessionId,
    status: ChatbotSessionStatus.Active,
  })),
  on(ChatbotSessionActions.clearCurrentSessionId, (state) => ({
    ...state,
    currentSessionId: null,
    status: ChatbotSessionStatus.NotInitialized,
  })),
  on(ChatbotSessionActions.setStatus, (state, action) => ({
    ...state,
    status: action.status,
  })),
  on(ChatbotSessionActions.addChatbotSession, (state, action) =>
    adapter.addOne(action.chatbotSession, state)
  ),
  on(ChatbotSessionActions.upsertChatbotSession, (state, action) =>
    adapter.upsertOne(action.chatbotSession, state)
  ),
  on(ChatbotSessionActions.addChatbotSessions, (state, action) =>
    adapter.addMany(action.chatbotSessions, state)
  ),
  on(ChatbotSessionActions.upsertChatbotSessions, (state, action) =>
    adapter.upsertMany(action.chatbotSessions, state)
  ),
  on(ChatbotSessionActions.updateChatbotSession, (state, action) =>
    adapter.updateOne(action.chatbotSession, state)
  ),
  on(ChatbotSessionActions.updateChatbotSessions, (state, action) =>
    adapter.updateMany(action.chatbotSessions, state)
  ),
  on(ChatbotSessionActions.deleteChatbotSession, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ChatbotSessionActions.deleteChatbotSessions, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ChatbotSessionActions.loadChatbotSessions, (state, action) =>
    adapter.setAll(action.chatbotSessions, state)
  ),
  on(ChatbotSessionActions.clearChatbotSessions, (state) =>
    adapter.removeAll(state)
  )
);

export const chatbotSessionsFeature = createFeature({
  name: chatbotSessionsFeatureKey,
  reducer,
  extraSelectors: ({ selectChatbotSessionsState }) => ({
    ...adapter.getSelectors(selectChatbotSessionsState),
    selectCurrentSessionId: (state) => state.currentSessionId,
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } =
  chatbotSessionsFeature;
