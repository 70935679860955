import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { ChatbotSession, ChatbotSessionStatus } from "../models";

export const ChatbotSessionActions = createActionGroup({
  source: "ChatbotSession/API",
  events: {
    "Set Status": props<{ status: ChatbotSessionStatus }>(),
    "Set Current SessionId": props<{ sessionId: string }>(),
    "Clear Current SessionId": emptyProps(),

    //default actions
    "Load ChatbotSessions": props<{ chatbotSessions: ChatbotSession[] }>(),
    "Add ChatbotSession": props<{ chatbotSession: ChatbotSession }>(),
    "Upsert ChatbotSession": props<{ chatbotSession: ChatbotSession }>(),
    "Add ChatbotSessions": props<{ chatbotSessions: ChatbotSession[] }>(),
    "Upsert ChatbotSessions": props<{ chatbotSessions: ChatbotSession[] }>(),
    "Update ChatbotSession": props<{
      chatbotSession: Update<ChatbotSession>;
    }>(),
    "Update ChatbotSessions": props<{
      chatbotSessions: Update<ChatbotSession>[];
    }>(),
    "Delete ChatbotSession": props<{ id: string }>(),
    "Delete ChatbotSessions": props<{ ids: string[] }>(),
    "Clear ChatbotSessions": emptyProps(),
  },
});
