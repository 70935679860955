export interface ChatbotSession {
  id: string;
  topic: string;
}
export enum ChatbotSessionStatus {
  Active = "active",
  SelectTopic = "select_topic",
  NotInitialized = "not_initialized",
}

export interface InitSessionRequest {
  topic: string;
}
export interface InitSessionResponse {
  id: string;
  topic: string;
}
