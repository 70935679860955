import { createFeature, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ChatbotMessage } from "../models";
import { ChatbotMessageActions } from "../actions";

export const chatbotMessagesFeatureKey = "chatbotMessages";

export interface State extends EntityState<ChatbotMessage> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ChatbotMessage> =
  createEntityAdapter<ChatbotMessage>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(ChatbotMessageActions.addChatbotMessage, (state, action) =>
    adapter.addOne(action.chatbotMessage, state)
  ),
  on(ChatbotMessageActions.upsertChatbotMessage, (state, action) =>
    adapter.upsertOne(action.chatbotMessage, state)
  ),
  on(ChatbotMessageActions.addChatbotMessages, (state, action) =>
    adapter.addMany(action.chatbotMessages, state)
  ),
  on(ChatbotMessageActions.upsertChatbotMessages, (state, action) =>
    adapter.upsertMany(action.chatbotMessages, state)
  ),
  on(ChatbotMessageActions.updateChatbotMessage, (state, action) =>
    adapter.updateOne(action.chatbotMessage, state)
  ),
  on(ChatbotMessageActions.updateChatbotMessages, (state, action) =>
    adapter.updateMany(action.chatbotMessages, state)
  ),
  on(ChatbotMessageActions.deleteChatbotMessage, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ChatbotMessageActions.deleteChatbotMessages, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ChatbotMessageActions.loadChatbotMessages, (state, action) =>
    adapter.setAll(action.chatbotMessages, state)
  ),
  on(ChatbotMessageActions.clearChatbotMessages, (state) =>
    adapter.removeAll(state)
  )
);

export const chatbotMessagesFeature = createFeature({
  name: chatbotMessagesFeatureKey,
  reducer,
  extraSelectors: ({ selectChatbotMessagesState }) => ({
    ...adapter.getSelectors(selectChatbotMessagesState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } =
  chatbotMessagesFeature;
