import { inject, Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http";
import { InitSessionRequest, InitSessionResponse } from "../models";
import { map, Observable } from "rxjs";
import { ChatbotTopic } from "../models/chatbot-topic.model";

@Injectable({
  providedIn: "root",
})
export class ChatbotSessionService {
  protected backendService = inject(BackendHttpClient);

  public init(data: InitSessionRequest): Observable<InitSessionResponse> {
    return this.backendService
      .post("chatbot/session/init", data)
      .pipe(map((response) => response as InitSessionResponse));
  }

  public getTopics(): Observable<ChatbotTopic[]> {
    return this.backendService
      .get("chatbot/topics")
      .pipe(map((response: any) => response.result.topics));
  }
}
