import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChatbotService } from "./services/chatbot.service";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { ChatbotEffectEffects } from "./effects";
import { chatbotMessagesFeature, chatbotSessionsFeature } from "./reducers";
import { ChatbotSessionService } from "./services";
import { chatbotFeature } from "./reducers/chatbot.reducer";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      chatbotSessionsFeature.name,
      chatbotSessionsFeature.reducer
    ),
    StoreModule.forFeature(
      chatbotMessagesFeature.name,
      chatbotMessagesFeature.reducer
    ),
    StoreModule.forFeature(chatbotFeature.name, chatbotFeature.reducer),
    EffectsModule.forFeature([ChatbotEffectEffects]),
  ],
  providers: [ChatbotService, ChatbotSessionService],
})
export class ChatbotModule {}
