export interface ChatbotMessage {
  id: string;
  sessionId: string;
  type: ChatbotMessageType;
  sender: "user" | "bot";
  text: string;
  createdAt: string;
}

export enum ChatbotMessageType {
  MESSAGE = "message",
  TOPIC = "topic",
}

export interface SendMessageRequest {
  query: string;
  sessionId: string;
}

export interface SendMessageResponse {
  sessionId: string;
  query: string;
  response: string;
}
