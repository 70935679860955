import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ChatbotTopic } from "../models/chatbot-topic.model";

export const ChatbotActions = createActionGroup({
  source: "Chatbot/API",
  events: {
    "Chatbot Opened": emptyProps(),
    "Chatbot Init": emptyProps(),
    "Chatbot Closed": emptyProps(),

    "Request Topics": emptyProps(),
    "Request Topics Success": props<{ topics: ChatbotTopic[] }>(),
    "Request Topics Failed": props<{ error: any }>(),

    "Select Topic": props<{ topic: ChatbotTopic }>(),
  },
});
